import { effect, inject, Injectable, untracked } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectMessage,
  selectMessageEntityState,
  selectMessageInfos,
  selectMessages,
  selectRecipient,
  selectRecipients,
} from './messages.selectors';
import {
  fetchMessage,
  fetchMessageInfos,
  replyMessage,
  sendMessage,
  setMe,
} from './messages.actions';
import { MessageDto } from '@swagger/humanresources';
import { inject$User } from '@core/auth';

@Injectable()
export class MessagesStore {
  private store = inject(Store);

  private $user = inject$User();

  messageInfos$ = this.store.select(selectMessageInfos);

  messages$ = this.store.select(selectMessages);

  recipients$ = this.store.select(selectRecipients);

  message$ = (messageUId: string) =>
    this.store.select(selectMessage(messageUId));

  recipient$ = (recipientUId: string) =>
    this.store.select(selectRecipient(recipientUId));

  $userChange = effect(() => {
    const user = this.$user();
    untracked(() => {
      this.store.dispatch(setMe({ me: user?.uId ?? null }));
    });
  });

  fetchMessageInfos() {
    this.store.dispatch(fetchMessageInfos.request());
  }

  fetchMessage({ messageUId }: { messageUId: string }) {
    this.store.dispatch(fetchMessage.request({ messageUId }));
  }

  sendMessage(message: MessageDto) {
    this.store.dispatch(sendMessage.request({ message }));
  }

  replyMessage(replyTo: MessageDto, message: MessageDto) {
    this.store.dispatch(
      replyMessage.request({
        message: { ...message },
        messageUId: replyTo.uId,
        messageReceipientUId: replyTo.senderEmployee.uId,
      })
    );
  }
}
