import {
  createAction,
  createActionGroup,
  emptyProps,
  props,
} from '@ngrx/store';
import { FEATURE_ACTION_PREFIX } from './messages.state';
import {
  ContributorInfoDto,
  MessageDto,
  MessageInfoDto,
} from '@swagger/humanresources';

const ACTION_PREFIX = FEATURE_ACTION_PREFIX;

export const initAction = createAction(`${ACTION_PREFIX} Init`);

export const setMe = createAction(
  `${ACTION_PREFIX} Set Me`,
  props<{ me: string | null }>()
);

export const fetchMessageInfos = createActionGroup({
  source: `${ACTION_PREFIX} Fetch Message Infos`,
  events: {
    Request: emptyProps(),
    Success: props<{ messages: MessageInfoDto[] }>(),
    Failure: props<{ error: any }>(),
  },
});

export const fetchMessage = createActionGroup({
  source: `${ACTION_PREFIX} Fetch Message`,
  events: {
    Request: props<{ messageUId: string }>(),
    Success: props<{ message: MessageDto }>(),
    Failure: props<{ error: any }>(),
  },
});

export const fetchRecipients = createActionGroup({
  source: `${ACTION_PREFIX} Fetch Recipients`,
  events: {
    Request: emptyProps(),
    Success: props<{ recipients: ContributorInfoDto[] }>(),
    Failure: props<{ error: any }>(),
  },
});

export const sendMessage = createActionGroup({
  source: `${ACTION_PREFIX} Send Message`,
  events: {
    Request: props<{ message: MessageDto }>(),
    Success: props<{ message: MessageDto }>(),
    Failure: props<{ error: any }>(),
  },
});

export const replyMessage = createActionGroup({
  source: `${ACTION_PREFIX} Reply Message`,
  events: {
    Request: props<{
      messageReceipientUId: string;
      messageUId: string;
      message: MessageDto;
    }>(),
    Success: props<{ message: MessageDto }>(),
    Failure: props<{ error: any }>(),
  },
});
